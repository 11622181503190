import React from 'react'

export default function Thank(props) {
    return (
        <div className="thank">
            <h4>Thank you for your Registration!</h4>
            <h5>Your Unique Code : { props.uniCode }</h5>
            <h6>Save this information for future purchase.</h6>
            <div>
                <button className="thank-button" onClick={() => props.handleClose()}>Back</button>
            </div>
        </div>
    )
}
