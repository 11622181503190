import { toast } from 'react-toastify';
import './toaster.scss';
export const toaster = (message, type) => {
    try {
        
   
    switch (type) {
        case "success":
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT,
                className: "toast-success-container toast-success-container-after",
                progressClassName: 'progress-success',
            })
            break;
        case "error":
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-error-container toast-error-container-after',
                progressClassName: 'progress-error',
            })
            break;
           default:
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-info-container toast-info-container-after',
                    progressClassName: 'progress-info',
                    autoClose: 6000,
                })
                break;

    }
} catch (error) {
        console.log(error)
}
}