import React, { useState } from "react";
// import { useHistory } from 'react-router-dom'
import { Formik } from 'formik';
import { Link } from "react-router-dom";
import axios from 'axios'

import { forgotSchema } from '../../validation';
import Input from '../../components/input';
import Loader from "../../components/loader";
import { toaster } from '../../components/toaster';
//
import LoginImage from '../../images/login-logo.png'

export default function ForgotAccount(props) {
    const [loading, setLoading] = useState(false)
    return (
        <Formik
            validationSchema={forgotSchema}
            initialValues={{
                email: '',
                phoneNumber: ''
            }}
            onSubmit={(values, { resetForm }) => {
                setLoading(true)
                const { email, phoneNumber } = values
                const data = { email, phone: phoneNumber }
                axios.post('https://api.nodw.in/forgotCustomerInfo', data, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((res) => {
                        if (res.data.code === 200) {
                            resetForm();
                            setLoading(false);
                            toaster(res.data.message, "success");
                        } else {
                            resetForm();
                            setLoading(false);
                            toaster(res.data.message, "error");
                        }
                    })
                    .catch((err) => {
                        resetForm();
                        setLoading(false);
                        toaster("!Oops, Something went wrong", "error");
                    });
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue
            }) => {
                return (

                    <div className="container">
                        <div className="login-main">
                            <div className="card">
                                <div className="welcome">
                                    <h5>Welcome</h5>
                                    <p>Register to continue to Nodwin.</p>
                                </div>

                                <div className="card-body">
                                    <img className="login-logo" src={LoginImage} alt="login-logo" />
                                    <form noValidate="" className="form-horizontal" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <Input
                                                id="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                type="text"
                                                label="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={touched.email && errors.email}
                                                errorText={errors.email}
                                                disabled={isSubmitting} />
                                        </div>

                                        <div className="form-group">
                                            <Input
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                placeholder="Enter Phone number"
                                                type="text"
                                                label="Phone Number"
                                                value={values.phoneNumber}
                                                onChange={handleChange}
                                                error={touched.phoneNumber && errors.phoneNumber}
                                                errorText={errors.phoneNumber}
                                                disabled={isSubmitting} />
                                        </div>

                                        <div className="form-group login-btn">
                                            <button type="submit" className="login-btn1 btn mt-3 ">
                                                {loading ? <Loader /> : 'Submit'}
                                            </button>
                                        </div>
                                        <div className="forgot text-center">
                                            <Link to="/">
                                                Register your Account ?
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Formik>
    );
}
