import * as yup from 'yup';

//validator
const emailRegex =  new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

const registerSchema = yup.object({
    username: yup.string().required("Customer Name is required!"),
    email: yup.string().matches(emailRegex, "Invalid email!").required("Please enter your email"),
    phoneNumber: yup.string()
        .matches(phoneRegex, "Invalid phone number!")
        .required("Phone number is required!"),
    address: yup.string().required("Address is required!"),
    terms: yup.bool().oneOf([true], "Please agree to terms and conditions.")
});

const forgotSchema = yup.object({
    email: yup.string().matches(emailRegex, "Invalid email!").required("Please enter your email"),
    phoneNumber: yup.string()
        .matches(phoneRegex, "Invalid phone number!")
        .required("Phone number is required!"),
});

export {
    registerSchema,
    forgotSchema
}