import React from 'react'
import './App.scss'
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//cmps
import RegisterForm from './container/registerForm'
import ForgotAccount from './container/forgotAccount'

export default function App() {
    return (
        <div>
            <Router>
                <Switch>
                    <Route path="/forgot" component={ForgotAccount} />
                    <Route path="/" component={RegisterForm} />
                </Switch>
            </Router>
            <ToastContainer role="alert" autoClose={4000}
                className="toast-container"
                toastClassName="dark-toast" />
        </div>
    )
}
